
import { defineComponent, computed, ref, onMounted, Ref } from 'vue'
import { Toaster } from '@/common/Toaster'
import { DigInputText } from '@digithia/input/text'
import { DigInputController } from '@digithia/input/common'
import router from '@/router'
import { __ } from '@/locales'
import { storeUser, StoreActionsUser } from '@/store/user'
import { storeAuth, StoreActionsAuth, StoreGettersAuth } from '@/store/auth'
import { DigUiPopup } from '@digithia/ui/popup'

export interface UserEditForm {
  username?: string
  email?: string
  password?: string
}

export default defineComponent({
  name: `Account`,
  components: {},
  props: {},
  emits: [],
  setup() {
    const passphrase = ref('')
    const src = ref('')
    const userForm: Ref<UserEditForm> = ref({})
    const editing = ref(false)
    const popupDeleteAccount: Ref<DigUiPopup | null> = ref(null)

    onMounted(async () => {
      storeUser.dispatch(StoreActionsUser.GET, loggedUser.value.id)
      const res = await storeUser.dispatch(
        StoreActionsUser.GET_IMAGE,
        loggedUser.value.id,
      )
      src.value = res.data
    })

    const canDelete = computed((): boolean => {
      return passphrase.value === __('deleteAccountPassphrase')
    })

    const loggedUser = computed(() => {
      return storeAuth.getters[StoreGettersAuth.USER]
    })

    const edit = (edit: boolean) => {
      editing.value = edit
      if (edit) {
        userForm.value = JSON.parse(JSON.stringify(loggedUser.value))

        setTimeout(() => {
          const passwordInput = document.querySelector(
            '#password',
          ) as DigInputText
          passwordInput.validator = (value) => !value || value.length >= 8
          passwordInput.errors.validator = __('invalidPassword').toString()
        })
      } else {
        userForm.value = JSON.parse(JSON.stringify(loggedUser.value))
      }
    }

    const updateAccount = async () => {
      if (DigInputController.checkInputsValidity('.form-edit')) {
        const res = await storeAuth.dispatch(StoreActionsAuth.UPDATE_ACCOUNT, {
          id: loggedUser.value.id,
          username: userForm.value.username,
          email: userForm.value.email,
          password: userForm.value.password,
        })
        Toaster.toast(res)
        if (res.success) {
          edit(false)
        }
      } else {
        Toaster.toast({
          type: 'warning',
          message: 'Le formulaire est invalide',
        })
      }
    }

    const deleteAccount = async () => {
      const res = await storeAuth.dispatch(
        StoreActionsAuth.DELETE,
        loggedUser.value.id,
      )
      Toaster.toast(res)
      if (res.success) {
        storeAuth.dispatch(StoreActionsAuth.LOGOUT)
        router.push('/login')
      }
    }

    const deleteUserImage = async () => {
      const res = await storeUser.dispatch(
        StoreActionsUser.DELETE_IMAGE,
        loggedUser.value.id,
      )
      Toaster.toast(res)
    }

    const saveUserImage = async (event: CustomEvent) => {
      const res = await storeUser.dispatch(StoreActionsUser.UPDATE_IMAGE, {
        base64: event.detail.base64,
        file: { name: event.detail.file.name },
        userId: loggedUser.value.id,
      })
      Toaster.toast(res)
      if (res.success) src.value = res.data
    }

    const closeDeletePopup = () => {
      popupDeleteAccount.value?.close()
    }

    const openDeletePopup = () => {
      popupDeleteAccount.value?.trigger()
    }

    return {
      passphrase,
      src,
      userForm,
      editing,
      canDelete,
      loggedUser,
      popupDeleteAccount,
      edit,
      updateAccount,
      deleteAccount,
      saveUserImage,
      deleteUserImage,
      openDeletePopup,
      closeDeletePopup,
    }
  },
})
