import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createBlock as _createBlock, withScopeId as _withScopeId, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

_pushScopeId("data-v-78a6ab80")
const _hoisted_1 = { class: "padding" }
const _hoisted_2 = ["title"]
const _hoisted_3 = ["title"]
const _hoisted_4 = ["title"]
const _hoisted_5 = {
  key: 0,
  class: "info"
}
const _hoisted_6 = { class: "title-2 center" }
const _hoisted_7 = { class: "user-info" }
const _hoisted_8 = { class: "value" }
const _hoisted_9 = { class: "value" }
const _hoisted_10 = { class: "value" }
const _hoisted_11 = { key: 1 }
const _hoisted_12 = { class: "title-2 center" }
const _hoisted_13 = {
  key: 2,
  class: "editing"
}
const _hoisted_14 = { class: "form-edit" }
const _hoisted_15 = ["value"]
const _hoisted_16 = ["value"]
const _hoisted_17 = ["value", "hint"]
const _hoisted_18 = { class: "validate-button" }
const _hoisted_19 = {
  ref: "popupDeleteAccount",
  id: "popup-delete-account"
}
const _hoisted_20 = { class: "popup-content" }
const _hoisted_21 = { class: "title-3 center" }
const _hoisted_22 = { class: "delete-account" }
const _hoisted_23 = { class: "info-text" }
const _hoisted_24 = { class: "form-delete" }
const _hoisted_25 = ["value"]
const _hoisted_26 = ["disabled"]
_popScopeId()

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_base_image_logo = _resolveComponent("base-image-logo")!
  const _component_base_control_grid = _resolveComponent("base-control-grid")!
  const _component_base_card = _resolveComponent("base-card")!
  const _component_base_container = _resolveComponent("base-container")!

  return (_openBlock(), _createBlock(_component_base_container, {
    marginTop: "",
    marginBottom: ""
  }, {
    default: _withCtx(() => [
      _createVNode(_component_base_card, { class: "account" }, {
        default: _withCtx(() => [
          _createVNode(_component_base_control_grid, { sideWidth: 120 }, {
            right: _withCtx(() => [
              _createElementVNode("div", _hoisted_1, [
                (!_ctx.editing)
                  ? (_openBlock(), _createElementBlock("dig-ui-icon", {
                      key: 0,
                      type: "delete",
                      class: "icon-button",
                      onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.openDeletePopup())),
                      title: _ctx.$t('delete')
                    }, null, 8, _hoisted_2))
                  : _createCommentVNode("", true),
                (!_ctx.editing)
                  ? (_openBlock(), _createElementBlock("dig-ui-icon", {
                      key: 1,
                      type: "edit",
                      class: "icon-button",
                      onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.edit(!_ctx.editing))),
                      title: _ctx.$t('edit')
                    }, null, 8, _hoisted_3))
                  : _createCommentVNode("", true),
                (_ctx.editing)
                  ? (_openBlock(), _createElementBlock("dig-ui-icon", {
                      key: 2,
                      type: "close",
                      class: "icon-button",
                      onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.edit(!_ctx.editing))),
                      title: _ctx.$t('cancel')
                    }, null, 8, _hoisted_4))
                  : _createCommentVNode("", true)
              ])
            ]),
            left: _withCtx(() => [
              _createVNode(_component_base_image_logo, {
                src: _ctx.src,
                defaultSrc: "@/assets/default-user.png",
                canEdit: true,
                onLoad: _cache[3] || (_cache[3] = ($event: any) => (_ctx.saveUserImage($event))),
                onDelete: _cache[4] || (_cache[4] = ($event: any) => (_ctx.deleteUserImage()))
              }, null, 8, ["src"])
            ]),
            middle: _withCtx(() => [
              (!_ctx.editing)
                ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                    _createElementVNode("dig-ui-title", _hoisted_6, _toDisplayString(_ctx.$t('myAccount')), 1),
                    _createElementVNode("div", _hoisted_7, [
                      _createElementVNode("div", null, [
                        _createTextVNode(_toDisplayString(_ctx.$t('User.username')) + " : ", 1),
                        _createElementVNode("span", _hoisted_8, _toDisplayString(_ctx.loggedUser.username), 1)
                      ]),
                      _createElementVNode("div", null, [
                        _createTextVNode(_toDisplayString(_ctx.$t('User.email')) + " : ", 1),
                        _createElementVNode("span", _hoisted_9, _toDisplayString(_ctx.loggedUser.email), 1)
                      ]),
                      _createElementVNode("div", null, [
                        _createTextVNode(_toDisplayString(_ctx.$t('User.role')) + " : ", 1),
                        _createElementVNode("span", _hoisted_10, _toDisplayString(_ctx.$t('Roles.' + _ctx.loggedUser.role)), 1)
                      ])
                    ])
                  ]))
                : _createCommentVNode("", true),
              (_ctx.editing)
                ? (_openBlock(), _createElementBlock("div", _hoisted_11, [
                    _createElementVNode("dig-ui-title", _hoisted_12, _toDisplayString(_ctx.$t('editMyData')), 1)
                  ]))
                : _createCommentVNode("", true),
              (_ctx.editing)
                ? (_openBlock(), _createElementBlock("div", _hoisted_13, [
                    _createElementVNode("div", _hoisted_14, [
                      _createElementVNode("dig-input-text", {
                        value: _ctx.userForm.username,
                        onInput: _cache[5] || (_cache[5] = ($event: any) => (_ctx.userForm.username = $event.target?.value)),
                        id: "username",
                        required: ""
                      }, _toDisplayString(_ctx.$t('User.username')), 41, _hoisted_15),
                      _createElementVNode("dig-input-text", {
                        value: _ctx.userForm.email,
                        onInput: _cache[6] || (_cache[6] = ($event: any) => (_ctx.userForm.email = $event.target?.value)),
                        id: "email",
                        type: "email",
                        required: ""
                      }, _toDisplayString(_ctx.$t('User.email')), 41, _hoisted_16),
                      _createElementVNode("dig-input-password", {
                        value: _ctx.userForm.password,
                        onInput: _cache[7] || (_cache[7] = ($event: any) => (_ctx.userForm.password = $event.target?.value)),
                        id: "password",
                        hint: _ctx.$t('leaveBlankToNotChange')
                      }, _toDisplayString(_ctx.$t('User.password')), 41, _hoisted_17)
                    ]),
                    _createElementVNode("div", _hoisted_18, [
                      _createElementVNode("dig-ui-standard-button", {
                        class: "cancel",
                        onClick: _cache[8] || (_cache[8] = ($event: any) => (_ctx.edit(false)))
                      }, _toDisplayString(_ctx.$t('cancel')), 1),
                      _createElementVNode("dig-ui-standard-button", {
                        onClick: _cache[9] || (_cache[9] = ($event: any) => (_ctx.updateAccount()))
                      }, _toDisplayString(_ctx.$t('edit')), 1)
                    ])
                  ]))
                : _createCommentVNode("", true)
            ]),
            _: 1
          }),
          _createElementVNode("dig-ui-popup", _hoisted_19, [
            _createElementVNode("div", _hoisted_20, [
              _createElementVNode("dig-ui-icon", {
                class: "close-popup",
                type: "close",
                onClick: _cache[10] || (_cache[10] = ($event: any) => (_ctx.closeDeletePopup()))
              }),
              _createElementVNode("dig-ui-title", _hoisted_21, _toDisplayString(_ctx.$t('deleteMyAccount')), 1),
              _createElementVNode("div", _hoisted_22, [
                _createElementVNode("div", _hoisted_23, [
                  _createTextVNode(_toDisplayString(_ctx.$t('askIfSurDeleteOwnAccount')) + " ", 1),
                  _createElementVNode("strong", null, "\"" + _toDisplayString(_ctx.$t('deleteAccountPassphrase')) + "\"", 1)
                ]),
                _createElementVNode("div", _hoisted_24, [
                  _createElementVNode("dig-input-text", {
                    value: _ctx.passphrase,
                    onInput: _cache[11] || (_cache[11] = ($event: any) => (_ctx.passphrase = $event.target?.value)),
                    id: "passphrase"
                  }, _toDisplayString(_ctx.$t('iAmSur')), 41, _hoisted_25),
                  _createElementVNode("dig-ui-standard-button", {
                    disabled: !_ctx.canDelete,
                    onClick: _cache[12] || (_cache[12] = ($event: any) => (_ctx.deleteAccount()))
                  }, _toDisplayString(_ctx.$t('delete')), 9, _hoisted_26)
                ])
              ])
            ])
          ], 512)
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}